import { translate } from '@optimusgps/optimus-intl';
import { message } from 'antd';
import copy from 'copy-to-clipboard';
import { ROLES } from '../constants/global';
import { CopyToClipBoard, ValidateMultimedia } from './utilities.type';

export const minimunValidationForMultimedia = ({
	roles,
	shared = false,
	isImpersonating,
}: ValidateMultimedia): boolean => !isImpersonating && !shared && roles.includes(ROLES.VIDEO_CAMERA);

export const capitilizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export const copyToClipBoard = ({ text, intl, successMessage, errorMessage }: CopyToClipBoard) => {
	if (copy(text)) {
		if (successMessage) {
			message.success(successMessage);
		} else {
			message.success(intl.formatMessage(translate('successCopy')));
		}
	} else {
		if (successMessage) {
			message.error(errorMessage);
		} else {
			message.error(intl.formatMessage(translate('badCopy')));
		}
	}
};
